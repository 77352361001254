import { PromptDialogContent } from '@/shared/model/prompt-dialog-content.model';
import { AppStore } from './types';

export const state: AppStore = {
  snackbar: false,
  snackbarContent: [],
  promptDialog: false,
  promptDialogContent: new PromptDialogContent(null, null, null, null),
  resolve: null,
  reject: null,
  customer: null,
  isRound: false,
  savingCustomer: false,
  diagramVisibility: null,
  capaVisibility: null,
};
