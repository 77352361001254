import Vuex from 'vuex';

import VueCookie from 'vue-cookie';
import Vuelidate from 'vuelidate';
import Vue2Filters from 'vue2-filters';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import de from 'vuetify/src/locale/de';

import * as filters from '@/shared/date/filters';
import { accountStore } from '@/shared/config/store/account-store';
import { appStore } from '@/shared/config/store/app-store';
import { setupAxiosInterceptors } from '@/shared/config/axios-interceptor';

export function initVueApp(vue) {
  vue.use(VueCookie);
  vue.use(Vuelidate);
  vue.use(Vue2Filters);
  vue.use(Vuetify);

  setupAxiosInterceptors(
    error => {
      const url = error.response?.config?.url;

      const status = error.status || error.response.status;
      if (status === 401) {
        // Store logged out state.
        //store.commit('logout');
        if (!url.endsWith('api/account') && !url.endsWith('api/authenticate')) {
          // Ask for a new authentication
          //loginService.openLogin(vue);
          return;
        }
      }
      console.log('Unauthorized!');
      return Promise.reject(error);
    },
    error => {
      console.log('Server error!');
      return Promise.reject(error);
    }
  );

  filters.initFilters();
}

export function initVueXStore(vue) {
  vue.use(Vuex);
  return new Vuex.Store({
    modules: {
      accountStore,
      appStore,
    },
  });
}

export function initVuetify(vue) {
  vue.use(Vuetify);
  const opts = {
    theme: {
      dark: false,
      themes: {
        light: {
          primary: '#539774',
          accent: '#D72323',
        },
        dark: {
          primary: '#147c78',
          accent: '#D72323',
          'lighten-4': '#000',
          'lighten-3': '#000',
          'dark-theme-custom-color': '#000',
        },
      },
    },
    lang: {
      locales: { de },
      current: 'de',
    },
  };
  return new Vuetify(opts);
}
