import axios from 'axios';

const baseApiUrl = 'api/customers/document';

import JhiDataUtils from '@/shared/data/data-utils.service';
import { IDocumentUploadDTO } from '@/shared/model/documentUploadDto.model';
import { IParameterHistory } from '@/shared/model/parameter-history.model';
const utils = new JhiDataUtils();
export default class DocumentsService {
  public getAllCustomersDocument(customerId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${customerId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public async postDocument(customerId: string, documentUpload: IDocumentUploadDTO, file: File): Promise<any> {
    documentUpload.base64Content = await utils.fileToBase64(file);
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/${customerId}`, documentUpload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public readDocument(documentId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/read/${documentId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public deleteDocument(documentId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${documentId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createNewOffer(offer: IParameterHistory): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      axios
        .post(baseApiUrl, offer)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
