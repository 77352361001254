import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

// prettier-ignore
const Age = () => import('@/entities/age/age.vue');
// prettier-ignore
const DigitalValue = () => import('@/entities/digital-value/digital-value.vue');
// prettier-ignore
const LifeExpectancy = () => import('@/entities/life-expectancy/life-expectancy.vue');
// prettier-ignore
const Guaranty = () => import('@/entities/guaranty/guaranty.vue');
// prettier-ignore
const ValueDeclarer = () => import('@/entities/value-declarer/value-declarer.vue');
// prettier-ignore
const MaritalStatus = () => import('@/entities/marital-status/marital-status.vue');
// prettier-ignore
const Province = () => import('@/entities/province/province.vue');
// prettier-ignore
const ModelASystemParameter = () => import('@/entities/system-parameter/model-a-system-parameter/model-a-system-parameter.vue');
// prettier-ignore
const ModelCSystemParameter = () => import('@/entities/system-parameter/model-c-system-parameter/model-c-system-parameter.vue');
// prettier-ignore
const ModelESystemParameter = () => import('@/entities/system-parameter/model-e-system-parameter/model-e-system-parameter.vue');
// prettier-ignore
const LiveDurationInput = () => import('@/entities/system-parameter/live-duration-parameter/live-duration.vue');
// prettier-ignore
const ModelAParameter = () => import('@/entities/model-a-parameter/model-a-parameter.vue');
// prettier-ignore
const ModelBParameter = () => import('@/entities/model-b-parameter/model-b-parameter.vue');
// prettier-ignore
const ModelCParameter = () => import('@/entities/model-c-parameter/model-c-parameter.vue');
// prettier-ignore
const ModelDParameter = () => import('@/entities/model-d-parameter/model-d-parameter.vue');
// prettier-ignore
const ModelEParameter = () => import('@/entities/model-e-parameter/model-e-parameter.vue');
// prettier-ignore
const ModelFParameter = () => import('@/entities/model-f-parameter/model-f-parameter.vue');
const DefaultDisplayValue = () => import('@/entities/default-display-value/default-display-value.vue');
// prettier-ignore
const Deviation = () => import('@/entities/deviations/deviation.vue');
const DocumentType = () => import('@/entities/document-type/document-type.vue');
const CustomerType = () => import('@/entities/customer-types/customer-types.vue');
const LeadSources = () => import('@/entities/lead-sources/lead-sources.vue');
const SecondLeadSources = () => import('@/entities/second-lead-sources/second-lead-sources.vue');
const MarketingActions = () => import('@/entities/marketing-actions/marketing-actions.vue');
const SalesAreas = () => import('@/entities/sales-areas/sales-areas.vue');
const SpecialLimits = () => import('@/entities/special-limits/special-limits.vue');
const ChanceWeight = () => import('@/entities/chance-weight/chance-weight.vue');

const LeadParameter = () => import('@/entities/lead-parameter/lead-parameter.vue');

const SystemEmails = () => import('@/entities/system-email/system-email.vue');

const OfferTypes = () => import('@/entities/offer-type/offer-type.vue');
export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'age',
      name: 'Age',
      component: Age,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'digital-values',
      name: 'DigitalValue',
      component: DigitalValue,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'life-expectancy',
      name: 'LifeExpectancy',
      component: LifeExpectancy,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'model-a-system-parameter',
      name: 'ModelASystemParameter',
      component: ModelASystemParameter,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'model-c-system-parameter',
      name: 'ModelCSystemParameter',
      component: ModelCSystemParameter,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'model-e-system-parameter',
      name: 'ModelESystemParameter',
      component: ModelESystemParameter,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'live-duration',
      name: 'LiveDurationInput',
      component: LiveDurationInput,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'guaranteed-values',
      name: 'Guaranty',
      component: Guaranty,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'value-declarer',
      name: 'ValueDeclarer',
      component: ValueDeclarer,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'marital-status',
      name: 'MaritalStatus',
      component: MaritalStatus,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'province',
      name: 'Province',
      component: Province,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'model-a-parameter',
      name: 'ModelAParameter',
      component: ModelAParameter,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'model-b-parameter',
      name: 'ModelBParameter',
      component: ModelBParameter,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'model-c-parameter',
      name: 'ModelCParameter',
      component: ModelCParameter,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'model-d-parameter',
      name: 'ModelDParameter',
      component: ModelDParameter,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'model-e-parameter',
      name: 'ModelEParameter',
      component: ModelEParameter,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'model-f-parameter',
      name: 'ModelFParameter',
      component: ModelFParameter,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'default-display-value',
      name: 'defaultDisplayValue',
      component: DefaultDisplayValue,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'deviation',
      name: 'Deviation',
      component: Deviation,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'document-type',
      name: 'DocumentType',
      component: DocumentType,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customers-type',
      name: 'CustomersType',
      component: CustomerType,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'lead-sources',
      name: 'LeadSources',
      component: LeadSources,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'second-lead-sources',
      name: 'SecondLeadSources',
      component: SecondLeadSources,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'marketing-actions',
      name: 'MarketingActions',
      component: MarketingActions,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sales-areas',
      name: 'SalesAreas',
      component: SalesAreas,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'special-limits',
      name: 'SpecialLimits',
      component: SpecialLimits,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'chance-weight',
      name: 'ChanceWeight',
      component: ChanceWeight,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'lead-parameters',
      name: 'leadParameters',
      component: LeadParameter,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'system-emails',
      name: 'systemEmails',
      component: SystemEmails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'offer-type',
      name: 'offerType',
      component: OfferTypes,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
