import axios from 'axios';
const baseUrl = 'api/customers/archive';

export default class ArchivedCustomersService {
  public retrieve(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseUrl)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public archiveCustomer(customerId: number, archive: boolean): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseUrl}/${customerId}`, archive, {
          headers: {
            'content-type': 'application/json',
          },
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
