import axios from 'axios';

import { IModelBParameter } from '@/shared/model/model-b-parameter.model';

const baseApiUrl = 'api/model-b-parameters';

export default class ModelBParameterService {
  public retrieve(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: IModelBParameter): Promise<IModelBParameter> {
    return new Promise<IModelBParameter>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
