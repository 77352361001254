var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app-bar",
    { attrs: { app: "", flat: "", height: "90px" } },
    [
      _c(
        "v-toolbar-title",
        { staticClass: "title ml-5" },
        [
          _c(
            "v-row",
            { staticClass: "ma-0", attrs: { align: "center" } },
            [
              _vm.authenticated
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", dark: "", icon: "", fab: "" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.toggleDrawer.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { "x-large": "" } }, [
                        _vm._v("mdi-menu"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "grey--text font-weight-bold text-h3 ma-0 ps-5",
                },
                [_vm._v("onCAPA Manager")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-spacer"),
      _vm._v(" "),
      _vm.customer && _vm.isCalculatorRoute
        ? _c(
            "v-col",
            { staticClass: "pa-5 ms-7" },
            [
              _vm.customer.firstPerson
                ? _c("v-row", { attrs: { align: "center" } }, [
                    _c("p", { staticClass: "ma-0 me-2" }, [
                      _vm._v(
                        _vm._s(
                          _vm.customer.firstPerson.title === "MR"
                            ? "Herr "
                            : "Frau "
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "ma-0" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatPersonalInfo")(_vm.customer.firstPerson)
                        )
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.hasPartner
                ? _c("v-row", { attrs: { align: "center" } }, [
                    _c("p", { staticClass: "ma-0 me-2" }, [
                      _vm._v(
                        _vm._s(
                          _vm.customer.secondPerson.title === "MR"
                            ? "Herr "
                            : "Frau "
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "ma-0" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatPersonalInfo")(
                            _vm.customer.secondPerson
                          )
                        )
                      ),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("v-spacer"),
      _vm._v(" "),
      _vm.isNotCustomerRoute
        ? _c(
            "v-btn",
            {
              staticClass: "me-5",
              attrs: {
                color: "primary",
                loading: _vm.savingCustomer,
                outlined: "",
              },
              on: { click: _vm.saveAndGoToCustomerList },
            },
            [
              _c("p", { staticClass: "ma-0 px-2" }, [_vm._v("kunden")]),
              _vm._v(" "),
              _c("v-icon", [_vm._v("mdi-account-multiple")]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-none d-md-flex" },
        [
          _c(
            "v-btn",
            { attrs: { icon: "" }, on: { click: _vm.changeTheme } },
            [
              _c("v-icon", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$vuetify.theme.dark
                        ? "mdi-white-balance-sunny"
                        : "mdi-weather-night"
                    )
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }