import axios from 'axios';
import { ILeadGeneratorParameter } from '@/shared/model/lead-generator-parameter.model';

const baseApiUrl = '/api/lead-parameters';

export default class LeadParameterService {
  public update(entity: ILeadGeneratorParameter): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(baseApiUrl, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
