var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-navigation-drawer",
    {
      attrs: { app: "", clipped: "", absolute: "", temporary: "" },
      scopedSlots: _vm._u(
        [
          _vm.authenticated
            ? {
                key: "append",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "pa-2", on: { click: _vm.logout } },
                      [
                        _c(
                          "v-btn",
                          { attrs: { block: "" } },
                          [
                            _c("v-icon", [_vm._v("mdi-logout")]),
                            _vm._v(" Ausloggen "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              }
            : null,
        ],
        null,
        true
      ),
      model: {
        value: _vm.drawer,
        callback: function ($$v) {
          _vm.drawer = $$v
        },
        expression: "drawer",
      },
    },
    [
      _c(
        "v-list-item",
        { staticClass: "mt-16" },
        [
          _c(
            "v-list-item-content",
            [
              _c("v-list-item-title", { staticClass: "text-h5" }, [
                _vm._v(_vm._s(_vm.username)),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "v-list",
        { attrs: { dense: "" } },
        [
          _vm.hasAdminAuthority || _vm.hasMarketerAuthority
            ? _c(
                "v-list-item",
                { attrs: { to: "/customer" } },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v("mdi-account-multiple-outline")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    [_c("v-list-item-title", [_vm._v("Kunden")])],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasAdminAuthority
            ? _c(
                "v-list-item",
                { attrs: { to: "/archived-customer" } },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v("mdi-account-off")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    [_c("v-list-item-title", [_vm._v("Archivierte Kunden")])],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasAdminAuthority || _vm.hasMarketerAuthority
            ? _c(
                "v-list-item",
                { attrs: { to: "/calendar" } },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v("mdi-calendar-text")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    [_c("v-list-item-title", [_vm._v("Kalender")])],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasAdminAuthority || _vm.hasMarketerAuthority
            ? _c(
                "v-list-item",
                { attrs: { to: "/estimate" } },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v("mdi-information-outline")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    [_c("v-list-item-title", [_vm._v("Estimating")])],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasAdminAuthority || _vm.hasMarketerAuthority
            ? _c(
                "v-list-item",
                { attrs: { to: "/system-analyse" } },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v("mdi-chart-areaspline")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    [_c("v-list-item-title", [_vm._v("System analyse")])],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasAdminAuthority || _vm.hasMarketerAuthority
            ? _c(
                "v-card",
                { attrs: { flat: "", color: _vm.dataGroupCardColor } },
                [
                  _c(
                    "v-list-group",
                    {
                      attrs: { "prepend-icon": "mdi-account-tie-voice" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function () {
                              return [
                                _c("v-list-item-title", [
                                  _vm._v("Berater Parameter"),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        814484441
                      ),
                      model: {
                        value: _vm.marketerParameterGroup,
                        callback: function ($$v) {
                          _vm.marketerParameterGroup = $$v
                        },
                        expression: "marketerParameterGroup",
                      },
                    },
                    [
                      _vm._v(" "),
                      _c(
                        "v-list-item-group",
                        [
                          _c(
                            "v-list-item",
                            { attrs: { to: "/default-display-value" } },
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("mdi-monitor")])],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-list-item-title", [_vm._v("Wertanzeige")]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item",
                            { attrs: { to: "/model-a-parameter" } },
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("mdi-alpha-a-box")])],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-list-item-title", [_vm._v("Modell A")]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item",
                            { attrs: { to: "/model-c-parameter" } },
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("mdi-alpha-c-box")])],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-list-item-title", [_vm._v("Modell C")]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item",
                            { attrs: { to: "/model-d-parameter" } },
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("mdi-alpha-d-box")])],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-list-item-title", [_vm._v("Modell D")]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item",
                            { attrs: { to: "/model-e-parameter" } },
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("mdi-alpha-e-box")])],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-list-item-title", [_vm._v("Modell E")]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item",
                            { attrs: { to: "/model-f-parameter" } },
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("mdi-alpha-f-box")])],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-list-item-title", [_vm._v("Modell F")]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasAdminAuthority
            ? _c(
                "v-card",
                { attrs: { flat: "", color: _vm.dataGroupCardColor } },
                [
                  _c(
                    "v-list-group",
                    {
                      attrs: { "prepend-icon": "mdi-tune" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function () {
                              return [
                                _c("v-list-item-title", [
                                  _vm._v("System Parameter"),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1349740619
                      ),
                      model: {
                        value: _vm.systemParameterGroup,
                        callback: function ($$v) {
                          _vm.systemParameterGroup = $$v
                        },
                        expression: "systemParameterGroup",
                      },
                    },
                    [
                      _vm._v(" "),
                      _c(
                        "v-list-item-group",
                        [
                          _c(
                            "v-list-item",
                            { attrs: { to: "/digital-values" } },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "warning" } },
                                    [_vm._v("mdi-weight")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-list-item-title", [_vm._v("Digitalwert")]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item",
                            { attrs: { to: "/chance-weight" } },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "warning" } },
                                    [_vm._v("mdi-cash-100")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-list-item-title", [
                                _vm._v("Chance Gewicht"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item",
                            { attrs: { to: "/age" } },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "warning" } },
                                    [_vm._v("mdi-tag-faces")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-list-item-title", [
                                _vm._v("Altersgrenzen"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item",
                            { attrs: { to: "/offer-type" } },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "warning" } },
                                    [_vm._v("mdi-format-list-checks")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-list-item-title", [_vm._v("Angebotstyp")]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item",
                            { attrs: { to: "/life-expectancy" } },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "warning" } },
                                    [_vm._v("mdi-account-heart")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-list-item-title", [
                                _vm._v("Lebenserwartung"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item",
                            { attrs: { to: "/guaranteed-values" } },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "warning" } },
                                    [_vm._v("mdi-home-thermometer")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-list-item-title", [
                                _vm._v("Garantiewerte"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item",
                            { attrs: { to: "/live-duration" } },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "warning" } },
                                    [_vm._v("mdi-one-up")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-list-item-title", [
                                _vm._v("Dauer des Wohnens"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item",
                            { attrs: { to: "/model-a-system-parameter" } },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "warning" } },
                                    [_vm._v("mdi-alpha-a-box")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-list-item-title", [
                                _vm._v("Modell A System Parameter"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item",
                            { attrs: { to: "/model-c-system-parameter" } },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "warning" } },
                                    [_vm._v("mdi-alpha-c-box")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-list-item-title", [
                                _vm._v("Modell C System Parameter"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item",
                            { attrs: { to: "/model-e-system-parameter" } },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "warning" } },
                                    [_vm._v("mdi-alpha-e-box")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-list-item-title", [
                                _vm._v("Modell E System Parameter"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item",
                            { attrs: { to: "/deviation" } },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "warning" } },
                                    [_vm._v("mdi-alpha-a-box")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-list-item-title", [_vm._v("Abweichungen")]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item",
                            { attrs: { to: "/special-limits" } },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "warning" } },
                                    [_vm._v("mdi-alpha-a-box")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-list-item-title", [_vm._v("Sonderfälle")]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-group",
                    {
                      attrs: { "prepend-icon": "mdi-cog-outline" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function () {
                              return [
                                _c("v-list-item-title", [_vm._v("Verwaltung")]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3278734004
                      ),
                    },
                    [
                      _vm._v(" "),
                      _c(
                        "v-list-item-group",
                        [
                          _c(
                            "v-list-item",
                            { attrs: { to: "/admin/user-management" } },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c("v-icon", [
                                    _vm._v("mdi-account-multiple"),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-list-item-title", [
                                _vm._v("Benutzer verwalten"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item",
                            { attrs: { to: "/system-emails" } },
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("mdi-email-outline")])],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-list-item-title", [
                                _vm._v("System Mail-Adressen"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.hasAdminAuthority
                            ? _c(
                                "v-list-item",
                                { attrs: { to: "/notaries" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-book-open-page-variant"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Notare"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-list-item",
                            { attrs: { to: "/value-sources" } },
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("mdi-more")])],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v("Quelle ImmoWert"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item",
                            { attrs: { to: "/value-Declarer" } },
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("mdi-cash-multiple")])],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-list-item-title", [_vm._v("Werteingaben")]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item",
                            { attrs: { to: "/province" } },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c("v-icon", [
                                    _vm._v("mdi-map-marker-radius-outline"),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-list-item-title", [_vm._v("Bundesland")]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item",
                            { attrs: { to: "/marital-status" } },
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("mdi-account-heart")])],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-list-item-title", [
                                _vm._v("Familienstand"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item",
                            { attrs: { to: "/document-type" } },
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("mdi-file-document")])],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-list-item-title", [
                                _vm._v("Unterlagentypen"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item",
                            { attrs: { to: "/customers-type" } },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c("v-icon", [
                                    _vm._v("mdi-account-multiple"),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-list-item-title", [_vm._v("Kundentypen")]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item",
                            { attrs: { to: "/lead-sources" } },
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("mdi-source-branch")])],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-list-item-title", [_vm._v("Quelle1")]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item",
                            { attrs: { to: "/second-lead-sources" } },
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("mdi-source-branch")])],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-list-item-title", [_vm._v("Quelle2")]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item",
                            { attrs: { to: "/marketing-actions" } },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c("v-icon", [
                                    _vm._v("mdi-arrow-expand-all"),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-list-item-title", [
                                _vm._v("Marketing Actions"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item",
                            { attrs: { to: "/sales-areas" } },
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("mdi-salesforce")])],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-list-item-title", [_vm._v("Sales Areas")]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasAdminAuthority || _vm.hasMarketerAuthority
            ? _c(
                "v-list-item",
                { attrs: { to: "/lead-parameters" } },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v("mdi-web")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _vm._v("Leadgenerator Parameter"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }