import { Component, Vue } from 'vue-property-decorator';
import { ICustomer } from '@/shared/model/customer.model';

@Component
export default class AppNavbar extends Vue {
  changeTheme() {
    this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
  }

  private toggleDrawer() {
    this.$emit('toggleDrawer');
  }

  private closeDrawer() {
    this.$emit('closeDrawer');
  }

  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  private get isNotCustomerRoute() {
    return this.$route.fullPath != '/customer';
  }
  private get isCalculatorRoute() {
    return this.$route.fullPath === '/calculator';
  }
  private get customer(): ICustomer {
    return this.$store.getters['appStore/getCustomer'];
  }

  private get hasPartner() {
    return this.customer != null && this.customer.secondPerson != null && this.customer.secondPerson.firstname != null;
  }

  private async saveAndGoToCustomerList() {
    if (this.isCalculatorRoute) {
      this.savingCustomer = true;
    } else {
      await this.$router.push('/customer');
    }
  }

  private get savingCustomer(): boolean {
    return this.$store.getters['appStore/getSavingCustomer'];
  }

  private set savingCustomer(newSavingCustomer: boolean) {
    this.$store.dispatch('appStore/setSavingCustomer', newSavingCustomer).then(null);
  }
}
