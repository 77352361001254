import AccountService from '@/account/account.service';
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class AppNavDrawer extends Vue {
  @Inject('accountService') private accountService: () => AccountService;

  $refs;
  @Prop() toggleDrawer!: boolean;
  private hasAdminAuthorityValue = false;
  private hasUserAuthorityValue = false;
  private hasMarketerAuthorityValue = false;
  private navDrawer = false;
  private systemParameterGroup = false;
  private marketerParameterGroup = false;
  private marketerGroup = false;
  public drawer = false;
  public mini = true;

  private get dataGroupCardColor() {
    return this.systemParameterGroup ? '' : 'transparent';
  }

  private get marketerGroupCardColor() {
    return this.marketerGroup ? '' : 'transparent';
  }
  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  @Watch('toggleDrawer')
  public ontoggleDrawerChanged(val: boolean): void {
    this.drawer = !this.drawer;
  }

  public get username(): string {
    const account = this.$store.getters.account;
    return (account?.firstName ?? '') + ' ' + (account?.lastName ?? '');
  }

  public get hasAdminAuthority(): boolean {
    this.accountService()
      .hasAnyAuthorityAndCheckAuth('ROLE_ADMIN')
      .then(value => {
        this.hasAdminAuthorityValue = value;
      });
    return this.hasAdminAuthorityValue;
  }

  public get hasMarketerAuthority(): boolean {
    this.accountService()
      .hasAnyAuthorityAndCheckAuth('ROLE_MARKETER')
      .then(value => {
        this.hasMarketerAuthorityValue = value;
      });
    return this.hasMarketerAuthorityValue;
  }

  public get hasUserAuthority(): boolean {
    this.accountService()
      .hasAnyAuthorityAndCheckAuth('ROLE_USER')
      .then(value => {
        this.hasUserAuthorityValue = value;
      });
    return this.hasUserAuthorityValue;
  }

  private drawerChanged() {
    this.navDrawer = !this.$refs.drawer.isMiniVariant;
  }

  created() {
    this.hasAdminAuthorityValue = false;
    this.hasUserAuthorityValue = false;
    this.hasMarketerAuthorityValue = false;
  }

  public logout(): void {
    localStorage.removeItem('jhi-authenticationToken');
    sessionStorage.removeItem('jhi-authenticationToken');
    this.hasAdminAuthorityValue = false;
    this.hasUserAuthorityValue = false;
    this.$store.commit('logout');
    this.$router.push('/');
  }
}
