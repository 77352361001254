import axios from 'axios';

import { IModelCParameter } from '@/shared/model/model-c-parameter.model';

const baseApiUrl = 'api/model-c-parameters';

export default class ModelCParameterService {
  public retrieve(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: IModelCParameter): Promise<IModelCParameter> {
    return new Promise<IModelCParameter>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
